@import './variables.css';

html, body, #root, #app, .container{
    height: 100%;
}

a {
    color:black;

}

a:hover {
    color: var(--dark-gray);
}

#arrowBtn:hover {
    cursor: pointer;
}

#arrowBtn {
    height: 1.5rem;
    width: 1.5rem;
}

.form-control:focus{
    border-color: var(--light-gray);
    box-shadow: 0 0 0 0.2rem rgba(64, 64, 64, 0.25);

}

.card-body {
    padding:1rem;
}

#app {
    height:100%;
    margin:0;
    padding:0;
    width: 100%;
    background-color: var(--background-gray);
    overflow: hidden;
}

.container {
    max-width: none;
}

.form-control:disabled, .form-control[readonly]  {
    background-color: var(--sidebar-gray);
}