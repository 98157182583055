#traceTabs {
    margin-top: 20px;
    height:90%
}

#tabTitle.traceTabs {
    font-size: 1rem;
    text-align: center;
}

#tabTitle > .nav-link {
    padding-top: 1.8rem;
    padding-bottom: 2rem;
    height:5rem;
}

#tabTitle > .nav-link.active {
    background-color: var(--info)!important;
    height:100%;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px !important ;
}

#traceTabContentWrapper {
    border: none;
    height:100%
}

.fade.tab-pane {
    height:100%
}

.categoryFilter {
    cursor: pointer;
    display: inline-block;
    padding: 7px;
    margin: 5px;
    font-size: 0.8rem;
    background-color: var(--info-light);
    color:black;
    border-radius:15px;
}

.categoryFilter.chosen {
    background-color: var(--info);
    color: white;
}

#traceTabTableRow:hover {
    background-color: var(--info-light);
}

#categoryHeader {
    display: inline-block;
    padding: 7px;
    margin: 5px;
    font-size: 0.8rem;
    background-color: var(--info);
    color:white;
    border-radius:15px;
}

#addFilterBtn {
    height:25px;
    width: 25px;
    cursor:pointer;
    background-color: var(--sidebar-gray);
    border-radius: 15px;
    padding:5px;
    margin-left: 25px;
}

#addFilterBtn:hover {
    background-color: #c8c8c8;
}