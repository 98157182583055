@import './variables.css';

#home-main-body {
    height:88vh;
    margin: 0;
}

.btn-outline-secondary {
    color: var(--dark-gray)!important;
    border-color: var(--dark-gray)!important;
}

.btn-outline-secondary:hover {
    background-color: var(--dark-gray)!important;
    color: white!important;
}

#header {
    background-color: var(--sidebar-gray);
    height:12vh;
}

.navbar .nav-item:not(:last-child) {
    margin-right: 35px;
}

#infoPill {
    font-size: 0.85rem;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 20px;
}

#arrowBtn.left {
    margin-right: 20px;
}


@media all and (max-width: 1470px) {
    #searchInputGroup {
        width: 20rem;
    }
    #arrowBtn.left {
        margin:0;
    }
    #infoPill {
        margin:0;
    }
}

@media all and (min-width: 1470px) {
    #header-username {
        margin-right:8rem;
    }
    #search {
        padding-left: 3rem;
    }
    #searchInputGroup {
        width: 40rem;
    }
    #infoArrowCol {
        padding-left:4rem;
        padding-right:0;
    }
}

@media all and (min-width: 1800px) {
    #infoArrowCol {
        padding-left:8.2rem;
        padding-right:0;
    }
}

#mainviewcol {
    height: 85vh;
    flex:1;
    overflow-y: scroll;
    margin: 3vh 2vw 0 2vw;
    background-color: white;
    border-radius: 5px;
}

#information {
    margin: 2%;
    padding:1% 0 1% 0;
    border:1.5px solid var(--dark-gray);
    border-radius: 2px;
}

#informationHeader {
    font-size: 1rem;
    margin:0;
}

#informationContent {
    font-size: 0.8rem;
    margin:0;
    color: var(--dark-gray)
}

#mainPageAccordionHeader:hover {
    cursor: pointer;
}

#decCardOuter {
    width:98%;
    margin:1%;
    
}

#outerCardBody {
    position: relative;
}

/* TODO This is not perfect and adds shadow at end of box no matter the contents, also difficult to click on last card */
#outerCardBody:before {
    z-index: 1000;
    content:"";
    position:absolute;
    top:5px;
    right:0;
    height:90%;
    width:100px;
    background: linear-gradient(to right, rgba(0,0,0,0), #fff);
    background-attachment: local, local, local, local;
}

#decAlert {
    font-size: 0.9rem;
    width:fit-content;
    margin:0;
    margin-left:2%;
}

#decCard {
    font-size: 12px;
    margin:5px;
    height: 270px;
}

#decCard:hover {
    cursor: pointer;
    border:1px solid black;
}

#decCardDeck {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    overflow: auto;
}

#decInnerText {
    font-size: 0.75rem;
    height: 150px;
    overflow: hidden;
    position: relative;
}

#decInnerText:before {
    content:"";
    position:absolute;
    top:120px;
    left:0;
    height:30px;
    width:100%;
    background: linear-gradient(rgba(0,0,0,0), #fff);
}

#decInnerText ul {
    padding-left: 15px;
}

#homeDecDetailsIcons {
    height: 25px;
    width:25px;
}

.tab-pane {
    min-height: 30vh;
    font-size: 0.8rem;
    margin:0;
}

#tabContentHeader {
    margin:10px 0px 0px 0px;
    background-color:var(--sidebar-gray);
    height:33px;
    padding:10px 2px;
}

#tabContentTableRow {
    border-top: 1px solid gray;
    margin:0;
    padding:10px 0px 10px 0px;
}

#tabContentTableRow > ul {
    margin:0;
}

#tabContentTableRow:hover {
    background-color: var(--info-light);
}