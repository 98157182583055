@import './variables.css';

#memberModBody {
    font-size: 0.8rem;
}

#saveBtn {
    width:100%;
    margin-top: 2%;
    background-color: var(--sidebar-gray);
    color: var(--dark-gray);
    border-color: var(--dark-gray);
}
