@import './variables.css';

#btn {
    width:120px;
}

#formLabel {
    font-size: 0.9rem;
}

.form-group {
    margin-bottom: 0.5rem;
}

#submitBtn {
    width:100%;
    margin-top: 2%;
    background-color: var(--sidebar-gray);
    color: var(--dark-gray);
    border-color: var(--dark-gray);
}

#submitBtn:before {
    content: "Save"
}

#submitBtn:disabled {
    cursor: not-allowed;
}

#submitBtn:disabled:before {
    content: "Nothing to save"
}

#decTabs{
    width: 100%;
    margin: 15px;
}

#modTabs{
    min-height: 35vh;
}

#modTabs .tab-content {
    min-height: 26vh;
}

#tabTitle {
    background-color: var(--sidebar-gray);
    font-size: 12px;
}

#tabTitle:hover {
    background-color: var(--background-gray);
}

#tabTitle.active {
    background-color: var(--background-gray);
}

.tab-content {
    width: 100%;
    border: 1px solid var(--sidebar-gray);
}

#modTabRow {
    padding: 5px 30px 10px 30px;
}

#modTabRow:after {
    content:"";
    display:block;
    width:100%;
    border-bottom: 1px solid var(--sidebar-gray);
}

#editbtn {
    width:100%;
    padding: 0.3rem 3rem 0.3rem 3rem;
}

#backbtn {
    margin-left: 1%;
    width:120px;
}

#resetbtn {
    margin-left: 1%;
    width:120px;
    background-color: white;
    border-color:var(--red);
    color:var(--red);
}

#resetbtn:disabled {
    cursor: not-allowed;
}

#resetbtn:enabled:hover {
    background-color: var(--red);
    color: white;
}

#sideinfo {
    border-left: 1px solid gray;
    height:100%
}

#decisionText ul {
    padding-left: 15px;
}

#docCard {
    margin-top: 5px;
    padding:5px;
}

#subunitAddBtn:hover {
    cursor: pointer;
}

#noteRemoveBtn:hover {
    cursor: pointer;
}

#decisionIcons {
    height:1.5rem;
    width:1.5rem;
    margin: 0.6rem 1rem 0.6rem 1rem;
}

#decisionIcons:hover {
    cursor: pointer;
}



#unitTreeIcon {
    height: 1.2rem;
    width: 1.2rem;
    margin: 0.6rem 1rem 0.6rem 1rem;
    transform: rotate(180deg);
}

#unitTreeIcon:hover {
    cursor: pointer;
    color: black;
    background-color: var(--info-dark);
    border-radius: 10%;
}

#treeCircle {
    z-index: 1000;
}

#treeCircle:hover {
    fill: var(--sidebar-gray);
}

#treeBranch{
    padding-left: 5%;
    padding-right: 5%;
    background-color: var(--sidebar-gray);
    border-radius: 10px;
    margin-left: 2%;
}

#treeLeaf{
    padding-left: 5%;
    padding-right: 5%;
    background-color: var(--sidebar-gray);
    border-radius: 10px;
    margin-left: 2%;
}

#treeLeaf:hover {
    color:var(--info-dark);
}

#decListItems {
    display: inline-block;
    width: fit-content;
    margin: 5px;
    padding:5px 15px;
    margin-bottom: 3px;
    font-size: 1.2rem;
    background-color: var(--sidebar-gray);
    border-radius: 10px;
}

#decListItems:hover {
    cursor: pointer;
    color: var(--info-dark);
}

#decListItems:hover svg {
    color: black;
}

#decArrows {
    height:48px;
    width:fit-content;
    display: inline;
    padding:0.6rem;
    border-right: 1px solid black;
}

@media all and (min-width: 1750px) {
    #decArrows {
        padding-left:  100px;
        padding-right: 40px;
    }
}

@media all and (max-width: 1750px) {
    #decArrows {
        padding-left: 50px;
        padding-right: 0;
    }   
}

@media all and (max-width: 1450px) {
    #decArrows {
        padding: 0.6rem;
    }   
}

@media print {
    body * {
        visibility: hidden;
    }

    .modal-lg {
        width:100%;
        max-width: none;
    }

    #printSection, #printSection * {
        visibility: visible;
    }

    #printSection {
        width:100%;
        position:absolute;
        left:0;
        top:0;
    }
}

.ReactTags__tags {
    position: relative;
}

/* Styles for the input */
.ReactTags__tagInput {
    width: 100px;
    border-radius: 2px;
    display: inline-block;
    color: var(--dark-gray)
}
.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus,
.ReactTags__tagInput input.ReactTags__tagInputField:active {
    height: 31px;
    margin: 0;
    font-size: 12px;
    width: 100%;
    border: 1px solid var(--sidebar-gray);
}

/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
    border: 1px solid var(--sidebar-gray);
    background: white;
    color: var(--dark-gray);
    font-size: 12px;
    display: inline-block;
    padding: 5px;
    margin: 0 5px 0 0;
    border-radius: 2px;
}
.ReactTags__selected a.ReactTags__remove {
    color: var(--dark-gray);
    margin-left: 5px;
    cursor: pointer;
}
.ReactTags__remove {
    border: none;
    cursor: pointer;
    background: none;
    color: var(--dark-gray);
}

.relDecFilters {
    padding: 5px;
    margin: 20px 5px;
    background-color: var(--info);
    cursor: pointer;
    border-radius: 15px;
}

.relDecFilters.chosen {
    background-color: var(--info-light);
}

.directionEditModal {
    width: 80%;
    max-width: none!important;
}

#directionEditModalBody {
    min-height: 65vh;
}

#directionStageHeaders {
    margin-top: 50px;
    font-size: 1.5rem;
    color: var(--medium-gray);
    border-bottom: 1px solid var(--sidebar-gray);
}

#directionAddStage {
    cursor: pointer;
}

#directionAddStage:hover {
    background-color: var(--sidebar-gray);
    border-radius: 50%;
}

.stageRows {
    padding: 10px 10px;
}

.stageRows:hover {
    background-color: var(--background-gray);
}

.actionActive {
    background-color: var(--background-gray);
}

.stageRowBtns {
    cursor: pointer;
}

.stageRowBtns:hover {
    height:17px;
    width:17px;
}

#stageRowDescription {
    overflow: hidden;
}

#stageRowDescription:focus {
    height:100px;
    overflow: auto;
}

#directionStageRows {
    max-height: 350px;
    overflow-y: overlay;
    overflow-x: hidden;
}

.actionHidden {
    display:none;
}

.actionRow {
    background-color: var(--background-gray);
    margin: 0 0px 3px 0px;
    border-top: 1px dashed var(--sidebar-gray);
    border-left: 3px solid var(--sidebar-gray);
    border-bottom: 2px solid var(--sidebar-gray);
    border-radius: 0 0 10px 10px;
    padding: 10px;
}

#actionSummaryField:focus {
    height:80px;
}